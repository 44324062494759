import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { verticalSigningQueryKeys } from "../queries/verticalSigning/queryKeys"
import { useVerticalSigningMutation } from "./useVerticalSigningMutation"

export function useVerticalSignUpdate(
    signId,
    poleId,
    queryParameters,
    config = {},
) {
    const url = `vertical_signs/${signId}/`
    const queryClient = useQueryClient()

    return useVerticalSigningMutation(
        url,
        axiosInstance.patch,
        {
            ...queryParameters,
        },
        {
            ...config,
            onSuccess: (updatedData) => {
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.verticalSign(signId) })
                queryClient.setQueriesData({ queryKey: verticalSigningQueryKeys.pole(poleId) }, oldData => {
                    if (!oldData.vertical_signs) {
                        return oldData
                    }
                    const newVerticalSigns = oldData.vertical_signs.map(verticalSign => {
                        if (verticalSign.uuid === signId) {
                            return {
                                ...verticalSign,
                                tags: updatedData.tags,
                            }
                        } else {
                            return verticalSign
                        }
                    })
                    return ({
                        ...oldData,
                        vertical_signs: newVerticalSigns,
                    })
                })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.project() })
            },
        },
    )
}