import { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import { useUrlParams } from "@l2r-front/l2r-utils"

import { VerticalSignsSidebarComponents } from "../../components/VerticalSignsSidebarComponents"
import { useGetVerticalSignTypeDepth } from "../../hooks/utils/useGetVerticalSignTypeDepth"
import * as Styled from "./VerticalSigningMobileContent.styled"

export const VerticalSigningMobileContent = () => {
    const getVerticalSignTypeDepth = useGetVerticalSignTypeDepth()
    const navigate = useNavigate()
    const { getParam } = useUrlParams()

    const handleChangeFilter = useCallback((filters) => {
        const filterCodeDepth = getVerticalSignTypeDepth(filters.type)
        if (filterCodeDepth % 2 === 1) {
            const condition = getParam("verticalSigningCondition")
            navigate(`./code/${filters.type}?verticalSigningType=${filters.type}${condition ? `&verticalSigningCondition=${condition}` : ""}`)
        }
    }, [getParam, getVerticalSignTypeDepth, navigate])

    return (
        <Styled.Container>
            <VerticalSignsSidebarComponents handleChangeFilter={handleChangeFilter} />
        </Styled.Container>
    )
}