import {
    TextField as MuiTextField,
    styled,
    AutoCompleteWithChips,
} from "@l2r-front/l2r-ui"

export const TextField = styled(MuiTextField)(({ theme }) => ({
    "& MuiAutoComplete-input": {
        "& input + fieldset": {
            borderColor: theme["cta-bg/cta-bg-primary"],
        },
    },
}))

export const TextFieldStyle = {
    width: "100%",
    height: "100%",
    borderRadius: 12,
}
export const TextFieldInputStyle = {
    height: "100%",
    borderRadius: 12,
    fontSize: "13px",
    padding: "0px 28px 0px 14px",
}
export const TextFieldInputLabelStyle = {
}

export const Autocomplete = styled(AutoCompleteWithChips)(({ theme }) => ({
    width: "100%",
    minHeight: 48,
    borderRadius: theme.spacing(3),

    "& .MuiAutocomplete-popupIndicator": {
        transform: "none",
    },
}))