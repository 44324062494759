import { useRoutes, Navigate } from "react-router-dom"

import { SegmentSelectListener } from "@l2r-front/l2r-map-utils"
import { MapAdapterRoad, MapAdapterBoundingBox } from "@l2r-front/l2r-networks"

import { RoadwayDetailSegmentSelectListener } from "../../containers/RoadwayDetailSegmentSelectListener"
import { MobileRoadwayDetailPage } from "../../pages/MobileRoadwayDetailPage"
import { MobileRoadwayPage } from "../../pages/MobileRoadwayPage"

export const RoadwayMobileRoutes = () => {

    const roadwayRoutes = useRoutes([
        {
            path: "/*",
            element:
                <>
                    <MapAdapterBoundingBox />
                    <SegmentSelectListener />
                    <MobileRoadwayPage />
                </>,
        },
        {
            path: "/:road",
            element:
                <>
                    <MapAdapterRoad />
                    <RoadwayDetailSegmentSelectListener />
                    <MobileRoadwayDetailPage />
                </>,
        },
        { 
            path: "/:road/*", 
            element: <Navigate replace to="./"/>,
        },
    ])

    return roadwayRoutes
}