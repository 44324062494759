import { IconButton, Skeleton as UiSkeleton, styled, styledForMobile, Typography } from "@l2r-front/l2r-ui"

export const Container = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(5),
    marginTop: theme.spacing(5),
    width: "100%",
}))

export const TitleHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
})

export const FilledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
    borderRadius: theme.spacing(3),
    marginLeft: theme.spacing(1),

    "&:hover": {
        backgroundColor: theme.palette["cta-bg/cta-bg-primary"].dark,
    },
}))

export const Skeleton = styled(UiSkeleton)({
    height: 24,
    transform: "scale(1)",
    width: "100%",
})

export const TextWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
    width: "100%",
})

export const StateWrapper = styled("div")(({ color, theme }) => ({
    backgroundColor: theme.palette[color].main,
    borderRadius: theme.spacing(3),
    padding: theme.spacing(3),
    width: "50%",
}))

export const State = styled(Typography)(({ color, theme }) => ({
    color: theme.palette[color].main,
}))

export const ContentText = styledForMobile(Typography)(({ color, isMobile, theme }) => ({
    color: theme.palette[color].main,
    width: isMobile ? "fit-content" : "50%",
}))

export const Text = styledForMobile(Typography)(({ isMobile }) => ({
    whiteSpace: "nowrap",
    width: isMobile ? "fit-content" : "50%",
}))