import { Form as FormikForm } from "formik"

import {
    Button as UiButton,
    styled,
} from "@l2r-front/l2r-ui"


export const Form = styled(FormikForm)(() => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
}))

export const ButtonsWrapper = styled("div")(({theme}) => ({
    marginTop: theme.spacing(8),
    display: "flex",
    justifyContent: "right",
    gap: theme.spacing(5),
}))

export const Button = styled(UiButton)({
    textDecoration: "underline",
    textTransform: "none",
    "&:hover": {
        textDecoration: "underline",
    },
})

export const SubmitButton = styled(UiButton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    textTransform: "capitalize",
}))